@layer components {
    .collage-wrap {
        grid-template-columns: repeat(12, 8.33%);
        grid-template-rows: repeat(12, 8.33%);
        
        &--p-l {
            .collage-item:first-child {
                grid-column: 1 / 9;
                grid-row: 1 / 13;
            }
            .collage-item:last-child {
                grid-column: 3 / 13;
                grid-row: 4 / 11;
            }
        }
        
        &--l-p {
            .collage-item:first-child {
                grid-column: 1 / 11;
                grid-row: 4 / 10;
            }
            .collage-item:last-child {
                grid-column: 5 / 13;
                grid-row: 1 / 12;
            }
        }
        
        &--p-p {
            .collage-item:first-child {
                grid-column: 2 / 8;
                grid-row: 4 / 13;
            }
            .collage-item:last-child {
                grid-column: 6 / 13;
                grid-row: 1 / 13;
            }
        }

        &--l-l {
            .collage-item:first-child {
                grid-column: 1 / 10;
                grid-row: 6 / 10;
            }
            .collage-item:last-child {
                grid-column: 3 / 13;
                grid-row: 2 / 10;
            }
        }
        
    }
    
    .collage-item:hover {
        z-index: 9;
    }
    
    .header-logo {
        top: 10px;
        left: calc(50% - 25px);
        width: 50px;
        height: 50px;
        background: theme('colors.black');
        border-radius: 9999px;
        transform: translateY(0);
        transition: transform 1s theme('transitionTimingFunction.out-expo') 0.4s;
        overflow: hidden;
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
        
        svg {
            top: 12px;
            left: 7px;
            width: 38px;
            height: 38px;
            
        }
        
        span {
            box-shadow: inset 5px -5px 5px rgba(0, 0, 0, 0.45);
            border-radius: 9999px;
        }
        
        .-hide-header & {
            transform: translateY(-70px);
            transition: transform 0.4s theme('transitionTimingFunction.in-sine') 0.4s;
        }
        
        @include from(l) {
            top: 15px;
            left: calc(50% - 30px);
            width: 60px;
            height: 60px;
            
            svg {
                top: 16px;
                left: 9px;
                width: 44px;
                height: 44px;
                
            }
            
            .-hide-header & {
                transform: translateY(-80px);
            }
        }
    }
    
    .section-heading {
        @apply font-work font-extrabold text-32 m:text-48 leading-1 text-shadow-md text-center;
    }
    
    .list-heading {
        @apply font-work font-extrabold text-20 m:text-24 leading-1_2;
    }
    
    .list-heading-lg {
        @apply font-work font-extrabold text-28 m:text-36 leading-1_2;
    }
    
    .plugin-icon {
        width: 60px;
        height: 60px;
        
        @include from(m) {
            width: 80px;
            height: 80px;
        }
    }
    
    .plugin-icon-lg {
        width: 80px;
        height: 80px;
        
        @screen m {
            width: 100px;
            height: 100px;
        }
    }
    
    .link {
        border-bottom: 2px solid theme('colors.red.DEFAULT');
        transition: border-color 0.2s ease;
        
        &:hover {
            border-color: theme('colors.black');
        }
        
        &--inverted {
            &:hover {
                border-color: theme('colors.white');
            }
        }
    }

    .footer-katana {
        width: 32px;
        height: 32px;
    }
    
    .line-padding {
        box-shadow: 10px 0 0 #fff, -10px 0 0 #fff;
        box-decoration-break: clone;
    }

}
