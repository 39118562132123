.wysiwyg,
.wysiwyg-simple {
    b, strong {
        font-weight: 700;
    }
    
    i, em {
        font-style: italic;
    }
    
    a {
        border-bottom: 2px solid theme('colors.red.DEFAULT');
        transition: border-color 0.2s ease;
        
        @screen m {
            border-bottom-width: 3px;
        }
        
        &:hover {
            border-color: theme('colors.black');
        }
    }
    
    code {
        font-family: monospace;
        font-size: 13px;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        padding: 1px 6px;
        display: inline-block;
        letter-spacing: 0.2px;
        
        @screen m {
            font-size: 14px;
        }
    }
}

.wysiwyg {
    p, ul, ol, li {
        margin-bottom: calc((22/16) * 1em);

        &:last-child {
            margin-bottom: 0;
        }
    }
    
    h2 {
        font-size: 24px;
        font-weight: bold;
        margin-top: calc((22/16) * 1.5em);
    }
    h3 {
        font-weight: bold;
    }
    
    ul {
        list-style-type: '–';
        margin-left: 0.4em;
    }

    ol {
        list-style-type: decimal;
        margin-left: 1em;
    }

    li {
        display: list-item;
        position: relative;
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul li {
        padding-left: 1em;
    }

    ol li {
        padding-left: 0.4em;
    }
    
}

.wysiwyg-simple {
    a {
        border-bottom-width: 2px;
    }    
}
