@layer utilities {

    /**

        Værsågod custom Tailwind utilities
        https://tailwindcss.com/docs/adding-custom-styles#adding-custom-utilities

        These utilities are provided via tailwind.config.js:

        .gpu
        .anti
        .round
        .full
        .center
        .center-x
        .center-y
        .clear
        .scrollable
        .scrollable-x
        .scrollbar-hidden
        .fullheight

        .wrapper (container with outer margins and max content width)
        .outer-margins (container with outer margins, but no max-width)
        .gutters (gap-based gutters, both horizontal and vertical)
        .gutters-x (gap-based horizontal gutters)
        .gutters-y (gap-based vertical gutters)
        .pad-wrapper (wrapper meant to be used with pad-gutters children, i.e. like the old .g-wrap)
        .pad-gutters (padding-based gutters, both horizontal and vertical)
        .pad-gutters-x (padding-based horizontal gutters)
        .pad-gutters-y (padding-based vertical gutters)

     */
    .placed-wrap {
        position: relative;
        height: 0;
    }

    .placed-image {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }

    .content-block {
        @apply my-36 m:my-42;
    }

    @keyframes bgzoom {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.15);
        }
    }

    .animation-zoom {
        animation: bgzoom 20s ease-in-out alternate infinite;
    }

    .text-shadow-sm {
        text-shadow: 1px 1px 0 theme('colors.red.DEFAULT');
    }

    .text-shadow-md {
        text-shadow: 3px 3px 0 theme('colors.red.DEFAULT');
    }

    .text-shadow-lg {
        text-shadow: 5px 5px 0 theme('colors.red.DEFAULT');
    }
    

}
