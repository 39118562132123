.hljs {
    font-size: 12px;
    padding: 20px;
    
    @screen m {
        font-size: 14px;
        padding: 25px;
    }

}
